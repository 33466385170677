'<template>
	<div class="aboutContainer">
		<div class="banner"></div>
		<div class="content_container">
			<div class="font1">发展历程</div>
			<div class="font2"><span class="font2-text">DEVELOPMENT HISTORY</span><span class="splitLine"></span></div>
			<div class="infoContainer">
				
				<ul class="timeLine">
					<template v-for="(item,index) in articalList.data" >
						<li :key="item.id" v-if="index%2===0"><span class="info1">{{item.aSummary}}</span><span class="title1">{{item.aTitle}}</span></li>
						<li :key="item.id" v-if="index%2===1"><span class="info2">{{item.aSummary}}</span><span class="title2">{{item.aTitle}}</span></li>
					</template>
					<li class="endNode"><span onclick="window.scrollTo(0,0)"></span></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import {
		getArticles
	} from '@/api/request'
	import {
		Base64
	} from 'js-base64'
	export default {
		name: 'product',

		data() {
			return {
				articalList: [],
				currentContent: ""
			}
		},
		computed: {

		},
		mounted() {

		},
		created() {
			getArticles({
				currentPage: 1,
				pageSize: 50,
				topicCode: "development",
				sortName:"CREATETIME",
				sortString:"desc"
				
			}).then(resp => {
				console.log(resp.data.result);
				this.articalList = resp.data.result;
			})
		},
		methods: {
			viewItem(inputItem) {
				let _this = this;
				this.articalList.forEach(function(item) {
					if (inputItem.articalId == item.articalId) {
						item.active = true;
						_this.currentContent = Base64.decode(item.articleCotnent);

					} else {
						item.active = false;
					}
				});
			}
		}
	}
</script>
<style>
	.aboutContainer {
		min-height: 800px;
		background-color: #175068;
	}

	.banner {
		width: 100%;
		height: 10rem;
		background: url(../../public/images/introductionbanner.png) no-repeat;
		background-size: 100% 100%;
	}
	.timeLine li{
		list-style: none;
		height:4.24rem;
		padding-top:1.16rem;
		position: relative;
		
		
	}
	.timeLine li{
		list-style: none;
		height:4.24rem;
		padding-top:1.16rem;
		position: relative;
		
		
	}
	.endNode span{
		display: block;
		width:2rem;
		height:2rem;
		background: url(../../public/images/endIcon.png) no-repeat;
		position: absolute;
		left: 50%;
		transform: translateX(-50%) rotate(180deg);
		z-index: 1000;
		cursor:pointer;
	}
	.timeLine span{
		display: block;
		color:#FFFFFF;
		font-size: 0.8rem;
		
	}
	.timeLine .title1{
		text-align: right;
		height:1.92rem;
		width:11rem;
		background: url(../../public/images/historyarrow1.png) no-repeat;
		background-size: 11rem 1.92rem;
		background-position: -1rem 0rem;
		line-height: 1.92rem;
		padding-right:1rem;
		float:right;
		padding-right:2.5rem;
		position: relative;
	}
	.timeLine .title1::after{
		content: "";
		position: absolute;
		width:0.5rem;
		height:0.5rem;
		background-color: #4f87a0;
		display: block;
		z-index: 999;
		right: -0.25rem;top:50%;
		transform: translateY(-50%);
		border-radius: 50%;
		
	}
	.timeLine .title2{
		float:left;
		text-align: left;
		height:1.92rem;
		width:11rem;
		background: url(../../public/images/historyarrow2.png) no-repeat;
		background-size: 11rem 1.92rem;
		background-position: 1rem;
		line-height: 1.92rem;
		padding-left:2.5rem;
		position: relative;
		
	}
	.timeLine .title2::before{
		content: "";
		position: absolute;
		width:0.5rem;
		height:0.5rem;
		background-color: #2baeb6;
		display: block;
		z-index: 999;
		left: -0.25rem;top:50%;
		transform: translateY(-50%);
		border-radius: 50%;
		
	}
	.timeLine .info1{
		text-align: left;
		width:50%;
		float:right;
		height:1.92rem;
		line-height: 1.92rem;
		padding-left:2.5rem;
		position: relative;
	}
	.timeLine li:first-child .info1::after{
		content: "";
		width:1px;
		height:2rem;
		border-left: 1px dashed #4c849d;
		position: absolute;
		left:-1px;
		top:-1rem;
		z-index: 998;
	}
	.timeLine li:first-child .title1::before{
		content: "";
		width:0.88rem;
		height:1rem;
		background: url(../../public/images/up.png) no-repeat;
		background-size: 100% 100%;
		position: absolute;
		right:-0.4rem;
		top:-2rem;
		z-index: 998;
	}
	.timeLine .info1::before{
		content: "";
		width:1px;
		height:4.24rem;
		border-left: 1px dashed #4c849d;
		position: absolute;
		left:-1px;
		top:1.16rem;
		z-index: 998;
		
	}
	.timeLine .info2{
		text-align: right;
		width:50%;
		float:left;
		height:1.92rem;
		line-height: 1.92rem;
		padding-right:2.5rem;
		position: relative;
	}
	.timeLine .info2::after{
		content: "";
		width:1px;
		height:4.24rem;
		border-left: 1px dashed #4c849d;
		position: absolute;
		right:-1px;
		top:1.16rem;
		z-index: 998;
		
	}
	.timeLine .arrowTop{}
</style>
